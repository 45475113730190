<template>
	<mw-dialog v-model="dialog" small submitBtn cancelBtn @submit="submit" :title="$t('assessment.notes')">
		<template v-slot:button>
			<v-tooltip v-if="icon" left>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon @click="dialog = true">
						<v-icon :color="response.notes ? 'accent' : ''">mdi-comment-edit</v-icon>
					</v-btn>
				</template>
				Add Notes
			</v-tooltip>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("assessment.notes") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<div v-html="$t('assessment.before_notes')"></div>

			<v-textarea v-model="response.notes"></v-textarea>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "Notes",
	props: {
		id: { type: String }, // the ID of the response,
		icon: { type: Boolean },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		submit() {
			const self = this;
			self.$store
				.dispatch("sectionResponses/patch", {
					id: self.id,
					notes: self.response.notes,
				})
				.then(() => {
					self.dialog = false;
				});
		},
	},
};
</script>
